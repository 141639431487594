import React from "react"
import "./Card.scss"

export default function CardThree({ icon, heading, description }) {
  return (
    <div className="card-three">
      <div className="img-wrapper">
        <img src={icon} alt={heading} loading="lazy" />
      </div>
      <h4>{heading}</h4>
      <p>{description}</p>
    </div>
  )
}
