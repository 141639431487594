import React from "react"
import "../../CaseStudy/Hero/hero-styles.scss"
const techTags = [
  {
    id: 1,
    name: "Product Design",
  },
  {
    id: 2,
    name: "Design Sprint",
  },
  {
    id: 3,
    name: "Front-End",
  },
  {
    id: 4,
    name: "Back-End",
  },
  {
    id: 5,
    name: "DevOps",
  },
]

const aboutSections = [
  {
    id: 1,
    heading: "Industry Vertical",
    description: "Automotive, Logistics Management, Shipment Management",
  },
  {
    id: 2,
    heading: "Business Model",
    description: "B2B SaaS",
  },
  {
    id: 3,
    heading: "Our Work",
    description: "Custom Software Development, Product Design, DevOps",
  },
]

export default function Hero() {
  return (
    <div className="styled-hero">
      <div className="container mt-5">
        <div className="row align-items-center overflow-hidden">
          <div className="col-12 col-md-5 col-xl-6">
            <div
              className="text-side"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="250"
            >
              <div className="driverbee-logo">
                <img
                  src="/driverbee/logo.svg"
                  alt="driverbee-logo"
                  loading="lazy"
                />
              </div>
              <h1>Logistics and Shipment Management</h1>
              <div className="tech-tags">
                {techTags.map((tag, i) => (
                  <div key={`driverbee-tag-${i}`} className="driverbee-tag">
                    {tag?.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-xl-6">
            <div
              className="hero-img-side"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="250"
            >
              <div className="image-wrapper">
                <img
                  src="/driverbee/driverbee-hero.png"
                  alt="driverbee-hero"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>

        {/* ABOUT PROJECT */}
        <div className=" mt-5">
          <div
            className="about-driverbee"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="250"
          >
            <div className="row">
              {aboutSections.map((section, i) => (
                <div
                  key={`driverbee-section-${i}`}
                  className="col-xs-12 col-sm-12 col-md-4"
                >
                  <div
                    className={`about-section-driverbee ${
                      i !== 0 ? "left-border" : ""
                    }`}
                  >
                    <h4>{section?.heading}</h4>
                    <p>{section?.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
