import React from "react"
import SmallCard from "../../shared/Card/SmallCard"
import "./businessNeeds.scss"

const businessNeedsList = [
  {
    id: 1,
    heading: "Automated solution for DSPs",
    description: "",
    icon: "/driverbee/businessNeeds/1.svg",
  },
  {
    id: 2,
    heading: "Increased overall business performance",
    description: "",
    icon: "/driverbee/businessNeeds/2.svg",
  },
  {
    id: 3,
    heading: "Improved operational efficiency and effectiveness",
    description: "",
    icon: "/driverbee/businessNeeds/3.svg",
  },
  {
    id: 4,
    heading: "Enhanced decision making with access to real-time",
    description: "",
    icon: "/driverbee/businessNeeds/4.svg",
  },
  {
    id: 5,
    heading: "Better resource allocation and utilization",
    description: "",
    icon: "/driverbee/businessNeeds/5.svg",
  },
  {
    id: 6,
    heading: "Increased productivity and profitability",
    description: "",
    icon: "/driverbee/businessNeeds/6.svg",
  },
  {
    id: 7,
    heading: "Streamlined coaching and development of drivers",
    description: "",
    icon: "/driverbee/businessNeeds/7.svg",
  },
]

export default function BusinessNeeds() {
  return (
    <div className="driverbee-business-needs-section">
      <div className="container">
        <h2>Business Needs It Fulfills</h2>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
          {businessNeedsList.map((solution, index) => (
            <div className="business-need-card">
              <SmallCard {...solution} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
