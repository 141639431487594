import React from "react"
import "./keyChallenges.scss"

const DotIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#F23B48" />
    </svg>
  )
}

export default function KeyChallengaes() {
  return (
    <div className="key-challenges-main">
      <div className="container">
        <div className="row align-items-center">
          {/* Key points */}
          <div className="col-12 col-md-6">
            <div
              className="points-side"
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="250"
            >
              <h2>Key Challenges</h2>
              <ul>
                <li>
                  <DotIcon />
                  <div className="challange-text">
                    <h6>Time-Consuming and Inefficient Manual Processes</h6>
                    <p>
                      Make the process more streamlined and efficient by
                      removing the manual effort in the report creation process.
                    </p>
                  </div>
                </li>
                <li>
                  <DotIcon />
                  <div className="challange-text">
                    <h6>
                      Automation of Analytical Report Creation and Driver
                      Evaluation
                    </h6>
                    <p>
                      Integration of Business Intelligence to reduce the manual
                      effort required to evaluate the progress and trait
                      assessment of drivers.
                    </p>
                  </div>
                </li>
                <li>
                  <DotIcon />
                  <div className="challange-text">
                    <h6>Streamlining Processes to Reduce Manual Effort</h6>
                    <p>
                      Helping business admins to monitor driver’s development
                      and areas for improvement with better controls and
                      visibility.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* Image Area */}
          <div className="col-12 col-md-6">
            <div
              className="keys-image-wrapper-driverbee"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="250"
            >
              <img
                src="/driverbee/keys-img.png"
                alt="driverbee-keys-image"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
