import React from "react"
import "./techStack.scss"

const techImages = [
  {
    id: 1,
    icon: "/driverbee/techStack/python.svg",
  },
  {
    id: 2,
    icon: "/driverbee/techStack/rest.svg",
  },
  {
    id: 3,
    icon: "/driverbee/techStack/php.svg",
  },
  {
    id: 4,
    icon: "/driverbee/techStack/django.svg",
  },
  {
    id: 5,
    icon: "/driverbee/techStack/react.svg",
  },
  {
    id: 6,
    icon: "/driverbee/techStack/mui.svg",
  },
  {
    id: 7,
    icon: "/driverbee/techStack/bootstrap.svg",
  },
  {
    id: 8,
    icon: "/driverbee/techStack/chakra.svg",
  },
  {
    id: 9,
    icon: "/driverbee/techStack/amazon.svg",
  },
  {
    id: 10,
    icon: "/driverbee/techStack/blue.svg",
  },
  {
    id: 11,
    icon: "/driverbee/techStack/c.svg",
  },
  {
    id: 12,
    icon: "/driverbee/techStack/bitbucket.svg",
  },
]

export default function TechStack() {
  return (
    <div className="driver-bee-techstack">
      <div className="container">
        <div className="driver-bee-grid-container">
          {techImages.map((tech, i) => (
            <div className="single-tech-grid">
              <div className="single-tech">
                <img src={tech.icon} alt={`tech-${i}`} loading="lazy" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
