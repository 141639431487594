import React from "react"
import CardThree from "../../shared/Card/CardThree"
import "./results.scss"

const resultsList = [
  {
    id: 1,
    heading: "Improved Performance Analysis",
    description: `A Smart Dashboard and Trait Assessment Feature provide insights into driver behavior and performance.`,
    icon: "/driverbee/results/1.svg",
  },
  {
    id: 2,
    heading: "Streamlined Performance Tracking",
    description:
      "With the Tracking Feature, managers can monitor driver performance in real-time and analyze the data with ease.",
    icon: "/driverbee/results/2.svg",
  },
  {
    id: 3,
    heading: "Increased Accuracy of Data",
    description:
      "The File Parsing Feature generates analytics reports based on the data collected by the application, resulting in accurate data analysis.",
    icon: "/driverbee/results/3.svg",
  },
]

export default function Results() {
  return (
    <div className="driverbee-results-section">
      <div className="container">
        <div className="heading-desc">
          <h2> Results obtained </h2>
        </div>

        <div className="cards-container">
          <div className="">
            <div className="row g-3">
              {" "}
              {resultsList.map(result => (
                <div key={result.id} className="col-12 col-md-4">
                  <CardThree {...result} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
