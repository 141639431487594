import React from "react"
import "./Card.scss"

export default function SmallCard({ icon, heading, description }) {
  return (
    <div className={`small-card ${description.trim() ? "" : "no-description"}`}>
      <div className="icon-wrapper">
        <img src={icon} alt={heading} loading="lazy" />
      </div>
      <h3>{heading}</h3>
      <p>{description}</p>
    </div>
  )
}
